import * as api from '../api/reports';
import * as contactApi from '../api/contacts';
import * as companiesApi from '../api/companies';
import {
  FETCH_TRIAL_BALANCE_SUCCESS,
  FETCH_TRIAL_BALANCE_FAIL,
  SHOW_DETAIL_VIEW,
  SET_ROUTES_DETAILS,
  SET_ROUTING_STATUS,
  SET_GROUP_DETAILS,
  SHOW_LEDGER_VIEW,
  SET_LEDGER_DETAILS,
  RESET_TO_REPORT_MODE_HOME,
  SHOW_YEARLY_VIEW,
  SHOW_MONTHLY_VIEW,
  SHOW_PERIOD_DIALOG,
  CHANGE_DATE_RANGE,
  EXPAND_ACCOUNT,
  EXPAND_ALL_ACCOUNT,
  SET_PROFIT_LOSS,
  SET_REPORT_MODE,
  SET_BALANCE_SHEET,
  SHOW_DAY_VIEW,
  SET_REPORT_VOUCHER_TYPE,
  RESET_GROUP_DETAILS,
  SHOW_INVENTORY_VIEW,
  REPORTS_DATA_REQUESTED,
  REPORTS_DATA_SUCCESS,
  REPORTS_DATA_FAILED,
  SHOW_INVENTORY_GROUP_VIEW,
  SHOW_ACCOUNTS_VIEW,
  SHOW_ACCOUNT_VIEW,
  FETCH_INVENTORY_MONTH_VIEW,
  SET_REPORT_PARTY_FILTER,
  SET_REPORT_BRANCH_FILTER,
  SET_REPORT_VOUCHER_TYPE_FILTER,
  SET_REPORT_DATE_FILTER,
  SET_REPORT_INCLUDE_TYPE_FILTER,
  TOGGLE_REPORT_FILTERS,
  SET_REPORT_CITY_FILTER,
  SET_REPORT_INCLUDE_SUNDRY_CRDR_FILTER,
  FETCH_STATE_REPORT_SUCCESS,
  FETCH_STATE_REPORT_FAIL,
  RESET_REPORT_FILTERS,
  SET_REPORT_PARTIES_STATES_FILTER,
  RESET_REPORT_CITY_FILTER,
  SET_REPORT_DATE_FILTER_RANGE,
  SET_REPORT_INTERVAL_FILTER,
  SET_NUMERIC_BARCODE_FILTER,
  FETCH_CITY_REPORT_FAIL,
  FETCH_CITY_REPORT_SUCCESS,
  SHOW_GSTR1_VIEW,
  SHOW_GSTR1_GROUP_VIEW,
  SHOW_GSTR1_STATEMENT_VIEW,
  PROGRESS_TRACKER,
  EXPORT_REPORTS_DATA_SUCCESS,
  EXPORT_REPORTS_DATA_REQUESTED,
  EXPORT_REPORTS_DATA_FAILED,
  SET_REPORT_TAGS_FILTER,
  SET_REPORT_SEARCH_TEXT,
  TOGGLE_REPORT_BARCODE_SEARCH,
  SHOW_INVENTORY_ALL_ITEMS_VIEW,
  SET_REPORT_ITEM_GROUP_FILTER,
  SET_REPORT_ITEM_TAGS_FILTER,
  TOGGLE_REPORT_PARTY_CODE,
  TOGGLE_REPORT_EXPORT_BUTTONS,
  FETCH_REPORT_VOUCHERS_LIST,
  SET_REPORT_VOUCHER_FILTER,
  SET_REPORT_SALES_PERSON_FILTER,
  SET_SALES_PERSON_DETAILS,
  SET_VENDOR_WISE_DETAILS,
  SHOW_VOUCHER_DUE_LIST,
  SHOW_PARTY_VOUCHER_DUE_LIST,
  SET_REPORT_DUE_DATE_FILTER_RANGE
} from './types';
import {
  PROFIT_AND_LOSS_ACC,
  TRIAL_BALANCE,
  PROFIT_AND_LOSS,
  BALANCE_SHEET,
  DAY_BOOK,
  SUNDRY_DEBTORS,
  SUNDRY_CREDITORS,
  ACC_GRPS,
  TAXES,
  EXPENSES,
  DIRECT_EXPENSES,
  INVENTORY,
  STATE_AND_CITY,
  PARTIES,
  CITIES,
  EXPORT_REPORT_MODAL,
  ERROR,
  REPORT_SEARCH_KEY,
  INVENTORY_ITEM_NAME_SEARCH_KEY,
  INVENTORY_ITEM_GROUP_SEARCH_KEY,
  REPORT_BARCODE_TOGGLE_KEY,
  INCLUDE_PARTY_CODE,
  CONTACTS,
  BARCODE_SEARCH_ERROR_MSG
} from '../constants';

import { serverError } from '../api/server';
import _ from 'lodash';
import {
  getValuesByKey,
  getFormattedDateIso,
  getStartTimeOfDay,
  getEndTimeOfDay
} from '../utils/helperFunctions';
import { toggleModalView } from './contacts';
import { openSnackbar } from './snackbar';
import { fetchVouchersSuggestionByNo } from '../api/vouchers';

export const getPartiesByState = callback => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  return api.fetchPartiesByState(id).then(res => {
    callback(res);
  });
};

export const fetchTrailBalance = () => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: { startDate, endDate, includeType, selectedBranches }
    }
  } = getState();

  let query = getDateQuery(startDate, endDate);

  query = setIncludeTypeQuery(includeType, query);

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }
  api
    .getTrialBalance(id, query)
    .then(response => {
      response.transactions.forEach(element => {
        element.open = false;
      });
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({ type: FETCH_TRIAL_BALANCE_SUCCESS, payload: response });
    })
    .catch(error => {
      dispatch({ type: REPORTS_DATA_FAILED });
      dispatch({ type: FETCH_TRIAL_BALANCE_FAIL, payload: error });
      serverError(error);
    });
};

export const getTrialBalance = () => dispatch => {
  dispatch({ type: SET_REPORT_MODE, reportMode: 'trial-balance' });
  dispatch(fetchTrailBalance());
};

export const getLedgerWiseReport = (page = 1) => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: { startDate, endDate, includeType, selectedBranches }
    }
  } = getState();

  let query = getDateQuery(startDate, endDate);

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }
  query = setIncludeTypeQuery(includeType, query);

  api
    .getLedgerWiseReport(id, query, page)
    .then(response => {
      // dispatch to groupDetails
      let payload = response;
      const { data, page, ...rest } = response;
      payload = { ...rest, ...data, pageNo: page };
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: SET_LEDGER_DETAILS,
        payload
      });
    })
    .catch(error => {
      dispatch({ type: REPORTS_DATA_FAILED });
      dispatch({ type: SET_GROUP_DETAILS, payload: error });
      serverError(error);
    });
};

export const exportLedgerWiseReport = (page = 1) => (dispatch, getState) => {
  const {
    currentCompany: { id },
    reports: {
      filters: { startDate, endDate, includeType, selectedBranches }
    }
  } = getState();
  let query = getDateQuery(startDate, endDate);

  query = setIncludeTypeQuery(includeType, query);

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }
  api
    .exportLedgerWiseReport(id, query, page)
    .then(response => {
      dispatch({
        type: PROGRESS_TRACKER,
        payload: {
          percentage: response.currentStatusInPercentage,
          isCompleted: response.isCompleted
        }
      });
      dispatch(trackExportStatus(response.id));
    })
    .catch(error => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(error);
    });
};

export const getAccountByPath = account => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      headerProperties: { path, groupName },
      filters: { startDate, endDate, includeType, selectedBranches }
    }
  } = getState();
  let accountPath, accountGrpName;
  if (account) {
    accountPath = account.path;
    accountGrpName = account.accountGroup;
  } else {
    accountPath = path;
    accountGrpName = groupName;
  }

  let query = `${getDateQuery(startDate, endDate)}&path=${accountPath}`;

  query = setIncludeTypeQuery(includeType, query);

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }

  api
    .getAccountByPath(id, query)
    .then(response => {
      // dispatch to groupDetails
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: SET_GROUP_DETAILS,
        payload: {
          response: response,
          // isAccount: account.isAccount,
          groupName: accountGrpName,
          path: accountPath
        }
      });
    })
    .catch(error => {
      dispatch({ type: REPORTS_DATA_FAILED });
      dispatch({ type: SET_GROUP_DETAILS, payload: error });
      serverError(error);
    });
};

export const showAccountGroupViewAction = (groupType, page = 1) => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      // dateRange: { startDate, endDate },
      filters: {
        selectedCities,
        includeType,
        includePartyAccountTypes,
        selectedPartiesStates,
        startDate,
        endDate,
        selectedPartiesTags,
        selectedBranches
      }
    }
  } = getState();
  let query = getDateQuery(startDate, endDate);
  if (groupType === PARTIES) {
    //filters only for sundry cr dr reports
    if (selectedCities && selectedCities.length > 0) {
      query += `&city=${getValuesByKey(selectedCities).toString()}`;
    }

    if (selectedPartiesStates && selectedPartiesStates.length > 0) {
      query += `&state=${getValuesByKey(selectedPartiesStates).toString()}`;
    }

    if (includePartyAccountTypes) {
      query += `&type=${includePartyAccountTypes}`;
    }

    if (selectedPartiesTags && selectedPartiesTags.length > 0) {
      query += `&tags=${getValuesByKey(selectedPartiesTags).toString()}`;
    }
  }

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }
  query = setIncludeTypeQuery(includeType, query);

  api
    .fetchAccountGroups(id, groupType, query, page)
    .then(response => {
      // dispatch to groupDetails
      let payload = response;
      if (groupType === PARTIES) {
        const { data, page, ...rest } = response;
        payload = { ...rest, ...data, pageNo: page };
      }
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: SET_GROUP_DETAILS,
        payload: {
          response: payload,
          // isAccount: account.isAccount,
          groupName: ACC_GRPS[groupType],
          // path: accountPath,
          reportMode: groupType
        }
      });
    })
    .catch(error => {
      dispatch({ type: REPORTS_DATA_FAILED });
      dispatch({ type: SET_GROUP_DETAILS, payload: error });
      serverError(error);
    });
};

export const fetchPartiesViewAction = (city, page = 1) => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      // dateRange: { startDate, endDate },
      filters: {
        selectedCities,
        includeType,
        includePartyAccountTypes,
        selectedPartiesStates,
        startDate,
        endDate,
        selectedPartiesTags,
        searchText,
        selectedBranches,
        selectedSalesPerson
      }
    }
  } = getState();
  let query = getDateQuery(startDate, endDate);

  if (searchText) {
    query += `&searchText=${searchText}`;
  }
  if (selectedCities && selectedCities.length > 0) {
    query += `&city=${getValuesByKey(selectedCities).toString()}`;
  } else if (city) {
    query += `&city=${city}`;
  }

  if (selectedPartiesStates && selectedPartiesStates.length > 0) {
    query += `&state=${getValuesByKey(selectedPartiesStates).toString()}`;
  }

  if (includePartyAccountTypes) {
    query += `&type=${includePartyAccountTypes}`;
  }

  if (selectedPartiesTags && selectedPartiesTags.length > 0) {
    query += `&tags=${getValuesByKey(selectedPartiesTags).toString()}`;
  }

  query = setIncludeTypeQuery(includeType, query);
  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  api
    .fetchAccountGroups(id, PARTIES, query, page)
    .then(response => {
      // dispatch to groupDetails
      let payload = response;
      const { data, page, ...rest } = response;
      payload = { ...rest, ...data, pageNo: page };

      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: SET_GROUP_DETAILS,
        payload: {
          response: payload,
          // isAccount: account.isAccount,
          groupName: ACC_GRPS[PARTIES],
          // path: accountPath,
          reportMode: PARTIES
        }
      });
    })
    .catch(error => {
      dispatch({ type: REPORTS_DATA_FAILED });
      dispatch({ type: SET_GROUP_DETAILS, payload: error });
      serverError(error);
    });
};

export const showYearlyViewAction = (accId, yearViewToggle = false, statementType, key) => (
  dispatch,
  getState
) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: { startDate, endDate, includeType, selectedSalesPerson },
      headerProperties: { accountId, groupName }
    },
    contacts
  } = getState();
  let accountIdToPass, accountNameToPass;
  if (accId) {
    accountIdToPass = accId;
  } else {
    accountIdToPass = accountId;
    accountNameToPass = groupName;
  }

  let query = getDateQuery(startDate, endDate, statementType);

  query =
    key === CONTACTS
      ? setIncludeTypeQuery(contacts?.includeType, query)
      : setIncludeTypeQuery(includeType, query);

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  api
    .getYearlyWiseReport(id, accountIdToPass, query)
    .then(response => {
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: SHOW_YEARLY_VIEW,
        payload: {
          response: response,
          accountName: accountNameToPass,
          accountId: accountIdToPass,
          yearViewToggle
        }
      });
    })
    .catch(err => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const showAccountStatementViewAction = (accId, pageNo = 1) => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: { startDate, endDate, includeType, selectedBranches, selectedSalesPerson },
      headerProperties: { accountId }
    }
  } = getState();
  const tempAccountId = accId ? accId : accountId;

  let query = `startDate=${getStartTimeOfDay(startDate)}&endDate=${getEndTimeOfDay(
    endDate
  )}&statementType=all`;

  query = setIncludeTypeQuery(includeType, query);

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }
  if (!tempAccountId) return;
  console.log(tempAccountId, '??????');

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  contactApi
    .accountStatementPagination(id, tempAccountId, pageNo, query)
    .then(response => {
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: SHOW_MONTHLY_VIEW,
        payload: {
          response,
          item: { startDate, endDate }
        }
      });
    })
    .catch(err => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const fetchPartyVouchersPaymentDue = (accountId, pageNo = 1) => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: { includeType, selectedBranches, dueStartDate, dueEndDate, selectedSalesPerson }
    }
  } = getState();

  let query = `accountId=${accountId}&${getDueDateQuery(dueStartDate, dueEndDate)}`;

  query = setIncludeTypeQuery(includeType, query);

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  api
    .fetchPartyVouchersPaymentDue(id, query, pageNo)
    .then(response => {
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: SHOW_PARTY_VOUCHER_DUE_LIST,
        payload: response
      });
    })
    .catch(err => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const showDayViewAction = (date, pageNo = 1) => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: {
        startDate,
        endDate,
        includeType,
        selectedVoucherType,
        selectedBranches,
        selectedVoucher,
        selectedSalesPerson
      }
      // dayBookAccountType
    },
    table: { sorting }
  } = getState();

  let query = `${getDateQuery(startDate, endDate)}&type=${selectedVoucherType}`;

  query = setIncludeTypeQuery(includeType, query);
  if (sorting.length > 0) {
    query += `&sortBy=${sorting[0].columnName}&sortOrder=${
      sorting[0].direction === 'asc' ? 'ASC' : 'DESC'
    }`;
  } else {
    query += `&sortBy=issueDate&sortOrder=DESC`;
  }
  if (selectedBranches && selectedBranches.length > 0) {
    query += `&branchIds=${getValuesByKey(selectedBranches).toString()}`;
  }
  if (selectedVoucher) query += `&voucherId=${selectedVoucher.id}`;

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  api
    .fetchDayBook(id, pageNo, query)
    .then(response => {
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: SHOW_DAY_VIEW,
        payload: {
          response: response,
          type: 'all'
        }
      });
    })
    .catch(err => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const fetchPartiesPaymentDue = (type, pageNo = 1) => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: {
        includeType,
        selectedBranches,
        selectedParties,
        dueStartDate,
        dueEndDate,
        searchText,
        selectedSalesPerson
      }
    },
    table: { sorting }
  } = getState();

  let query = `${getDueDateQuery(dueStartDate, dueEndDate)}`;

  query = setIncludeTypeQuery(includeType, query);

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (searchText) query += `&searchText=${searchText}`;

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  api
    .fetchPartiesPaymentDue(id, query, pageNo)
    .then(response => {
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: SHOW_VOUCHER_DUE_LIST,
        payload: response
      });
    })
    .catch(err => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const exportPartiesPaymentDue = (page = 1, detailedView) => (dispatch, getState) => {
  const {
    currentCompany: { id },
    reports: {
      filters: {
        includeType,
        selectedBranches,
        dueStartDate,
        dueEndDate,
        searchText,
        selectedSalesPerson
      }
    },
    table: { sorting }
  } = getState();

  let query = `${getDueDateQuery(dueStartDate, dueEndDate)}`;

  query = setIncludeTypeQuery(includeType, query);

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (searchText) query += `&searchText=${searchText}`;

  if (detailedView) query += '&detailedView=true';

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  api
    .exportPartiesPaymentDue(id, query, page)
    .then(response => {
      dispatch({
        type: PROGRESS_TRACKER,
        payload: {
          percentage: response.currentStatusInPercentage,
          isCompleted: response.isCompleted
        }
      });
      dispatch(trackExportStatus(response.id));
    })
    .catch(error => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(error);
    });
};

export const exportPartyVouchersPaymentDue = (accountId, page = 1) => (dispatch, getState) => {
  const {
    currentCompany: { id },
    reports: {
      filters: { includeType, selectedBranches, dueStartDate, dueEndDate, selectedSalesPerson }
    },
    table: { sorting }
  } = getState();

  let query = `accountId=${accountId}&${getDueDateQuery(dueStartDate, dueEndDate)}`;

  query = setIncludeTypeQuery(includeType, query);

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  api
    .exportPartyVouchersPaymentDue(id, query, page)
    .then(response => {
      dispatch({
        type: PROGRESS_TRACKER,
        payload: {
          percentage: response.currentStatusInPercentage,
          isCompleted: response.isCompleted
        }
      });
      dispatch(trackExportStatus(response.id));
    })
    .catch(error => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(error);
    });
};

export const getDayBookExport = (pageNo = 1, includeItemList = false) => (dispatch, getState) => {
  // dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: {
        startDate,
        endDate,
        includeType,
        selectedVoucherType,
        selectedBranches,
        includePartyCode,
        includeItemAttributesAndLabel,
        includeDiscountAndTax,
        selectedVoucher,
        selectedSalesPerson
      }
      // dayBookAccountType
    },
    table: { sorting }
  } = getState();

  let query = `${getDateQuery(startDate, endDate)}&type=${selectedVoucherType}`;
  query += `&includeItemList=${includeItemList}`;

  query = setIncludeTypeQuery(includeType, query);
  if (sorting.length > 0) {
    query += `&sortBy=${sorting[0].columnName}&sortOrder=${
      sorting[0].direction === 'asc' ? 'ASC' : 'DESC'
    }`;
  } else {
    query += `&sortBy=issueDate&sortOrder=DESC`;
  }
  if (selectedBranches && selectedBranches.length > 0) {
    query += `&branchIds=${getValuesByKey(selectedBranches).toString()}`;
  }
  if (includePartyCode) {
    query += `&includePartyCode=${includePartyCode}`;
  }
  if (includeItemAttributesAndLabel) {
    query += `&includeItemAttributesAndLabel=${includeItemAttributesAndLabel}`;
  }
  if (includeDiscountAndTax) {
    query += `&includeDiscountAndTax=${includeDiscountAndTax}`;
  }
  if (selectedVoucher) query += `&voucherId=${selectedVoucher.id}`;

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  api
    .fetchDayBookExport(id, pageNo, query)
    .then(response => {
      // dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: PROGRESS_TRACKER,
        payload: {
          percentage: response.currentStatusInPercentage,
          isCompleted: response.isCompleted
        }
      });
      dispatch(trackExportStatus(response.id));
    })
    .catch(err => {
      // dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const trackExportStatus = trackerId => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();

  companiesApi
    .fetchProgressTrackerStatus(id, trackerId)
    .then(response => {
      dispatch({
        type: PROGRESS_TRACKER,
        payload: {
          percentage: response.currentStatusInPercentage,
          isCompleted: response.isCompleted
        }
      });

      if (response.currentStatusInPercentage === 100 && !response.isCompleted) {
        dispatch(trackExportStatus(trackerId));
      } else if (response.currentStatusInPercentage === 100 || response.isCompleted) {
        window.open(response.result.info.Location, '_blank').focus();
      } else {
        setTimeout(() => dispatch(trackExportStatus(trackerId)), 5000);
      }
    })
    .catch(error => {
      console.log(error);
    });
};

export const showInventoryAction = (pageNo = 1, vendorId) => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    // table: { sorting: sort },
    reports: {
      filters: {
        selectedParties,
        selectedBranches,
        selectedVoucherTypes,
        startDate,
        endDate,
        includeType,
        [INVENTORY_ITEM_GROUP_SEARCH_KEY]: searchText,
        excludeZeroBalanceItemGroup,
        selectedSalesPerson
      }
    }
  } = getState();
  let query = `startDate=${getStartTimeOfDay(startDate)}&endDate=${getEndTimeOfDay(endDate)}`;
  if (searchText) {
    query += `&searchText=${searchText}`;
  }
  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (selectedVoucherTypes) {
    query += `&statementType=${selectedVoucherTypes}`;
  }

  if (selectedParties && selectedParties.id) {
    query += `&partyId=${selectedParties.id}`;
  }

  query = setIncludeTypeQuery(includeType, query);

  if (vendorId) {
    query += `&vendorId=${vendorId}`;
  }

  // if (sort.length > 0) {
  //   query += `&sort=${sort[0].columnName} ${
  //     sort[0].direction === TABLE_ASCENDING ? ASCENDING : DESCENDING
  //   }`;
  // }

  if (excludeZeroBalanceItemGroup) {
    query += `&includingZeroClosingBalanceItemGroup=${excludeZeroBalanceItemGroup}`;
  }

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  api
    .fetchInventory(id, pageNo, query)
    .then(response => {
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: SHOW_INVENTORY_VIEW,
        payload: {
          response: { data: response }
        }
      });
    })
    .catch(err => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const exportItemGroupWiseInventoryReport = (page = 1) => (dispatch, getState) => {
  const {
    currentCompany: { id },
    reports: {
      filters: {
        selectedParties,
        selectedBranches,
        selectedVoucherTypes,
        startDate,
        endDate,
        includeType,
        [INVENTORY_ITEM_GROUP_SEARCH_KEY]: searchText,
        includeItemLabel,
        selectedItemGroups,
        excludeZeroBalanceItemGroup,
        selectedItemTags
      }
    }
  } = getState();
  let query = `startDate=${getStartTimeOfDay(startDate)}&endDate=${getEndTimeOfDay(
    endDate
  )}&page=${page}`;
  if (searchText) {
    query += `&searchText=${searchText}`;
  }
  if (selectedBranches && selectedBranches.length > 0) {
    query += `&branchId=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (selectedVoucherTypes) {
    query += `&statementType=${selectedVoucherTypes}`;
  }

  if (selectedParties && selectedParties.id) {
    query += `&partyId=${selectedParties.id}`;
  }

  if (includeItemLabel) {
    query += `&includeItemLabel=${includeItemLabel}`;
  }

  if (selectedItemGroups && selectedItemGroups.length > 0) {
    query += `&itemGroupIds=${getValuesByKey(selectedItemGroups).toString()}`;
  }

  if (excludeZeroBalanceItemGroup) {
    query += `&includingZeroClosingBalanceItemGroup=${excludeZeroBalanceItemGroup}`;
  }

  if (selectedItemTags && selectedItemTags.length > 0) {
    query += `&tags=${getValuesByKey(selectedItemTags).toString()}`;
  }

  query = setIncludeTypeQuery(includeType, query);

  api
    .exportItemGroupWiseInventoryReport(id, query)
    .then(response => {
      dispatch({
        type: PROGRESS_TRACKER,
        payload: {
          percentage: response.currentStatusInPercentage,
          isCompleted: response.isCompleted
        }
      });
      dispatch(trackExportStatus(response.id));
    })
    .catch(err => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const showInventoryAllItemsAction = (pageNo = 1) => (dispatch, getState) => {
  const {
    currentCompany: { id },
    // table: { sorting: sort },
    reports: {
      filters: {
        selectedParties,
        selectedBranches,
        selectedVoucherTypes,
        startDate,
        endDate,
        includeType,
        [INVENTORY_ITEM_NAME_SEARCH_KEY]: searchText,
        selectedItemGroups,
        selectedItemTags,
        barcodeSearchOn,
        excludeZeroBalanceItemGroup,
        selectedSalesPerson
      }
    }
  } = getState();

  if (barcodeSearchOn && isNaN(searchText)) {
    dispatch(openSnackbar(BARCODE_SEARCH_ERROR_MSG, ERROR));
    return false;
  }

  let query = `startDate=${getStartTimeOfDay(startDate)}&endDate=${getEndTimeOfDay(endDate)}`;

  if (searchText) {
    const searchKey = barcodeSearchOn ? 'numericSkuBarcode' : 'searchText';
    query += `&${searchKey}=${searchText}`;
  }
  if (selectedBranches && selectedBranches.length > 0) {
    query += `&branchId=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (selectedVoucherTypes) {
    query += `&statementType=${selectedVoucherTypes}`;
  }

  if (selectedParties && selectedParties.id) {
    query += `&partyId=${selectedParties.id}`;
  }

  if (selectedItemGroups && selectedItemGroups.length > 0) {
    query += `&itemGroupIds=${getValuesByKey(selectedItemGroups).toString()}`;
  }

  if (selectedItemTags && selectedItemTags.length > 0) {
    query += `&tags=${getValuesByKey(selectedItemTags).toString()}`;
  }

  query = setIncludeTypeQuery(includeType, query);

  if (excludeZeroBalanceItemGroup) {
    query += `&includingZeroClosingBalanceItemGroup=${excludeZeroBalanceItemGroup}`;
  }

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  dispatch({ type: REPORTS_DATA_REQUESTED });

  api
    .fetchAllItemsInventory(id, pageNo, query)
    .then(response => {
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: SHOW_INVENTORY_ALL_ITEMS_VIEW,
        payload: {
          response: response
        }
      });
    })
    .catch(err => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const showAccountAction = account => dispatch => {
  const accountIdToPass = account.id ? account.id : account.accountId;
  const accountNameToPass = account.name;

  dispatch({
    type: SHOW_ACCOUNT_VIEW,
    payload: {
      accountName: accountNameToPass,
      accountId: accountIdToPass
    }
  });

  dispatch(showAccountStatementViewAction(accountIdToPass));
};

export const showAllAccountsAction = (page = 1) => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: { startDate, endDate, includeType, selectedBranches }
    }
  } = getState();

  let query = getDateQuery(startDate, endDate);

  query = setIncludeTypeQuery(includeType, query);
  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }
  api
    .getLedgerWiseReport(id, query, page)
    .then(response => {
      // dispatch to groupDetails
      let payload = response;
      const { data, page, ...rest } = response;
      payload = { ...rest, ...data, pageNo: page };
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: SET_LEDGER_DETAILS,
        payload: payload
      });
      dispatch({
        type: SHOW_ACCOUNTS_VIEW,
        payload: {
          response: payload,
          item: { startDate, endDate }
        }
      });
    })
    .catch(error => {
      dispatch({ type: REPORTS_DATA_FAILED });
      dispatch({ type: SET_GROUP_DETAILS, payload: error });
      serverError(error);
    });
};

export const showInventoryGroupViewAction = (page = 1, itemGroupId, vendorId) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id },
    reports: {
      filters: {
        selectedParties,
        selectedBranches,
        selectedVoucherTypes,
        startDate,
        endDate,
        includeType,
        barcodeSearchOn,
        [INVENTORY_ITEM_NAME_SEARCH_KEY]: searchText,
        excludeZeroBalanceItemGroup,
        selectedSalesPerson
      }
    }
  } = getState();

  if (barcodeSearchOn && isNaN(searchText)) {
    dispatch(openSnackbar(BARCODE_SEARCH_ERROR_MSG, ERROR));
    return false;
  }

  let query = `startDate=${getStartTimeOfDay(startDate)}&endDate=${getEndTimeOfDay(endDate)}`;

  if (vendorId) {
    query += `&vendorId=${vendorId}`;
  }
  if (searchText) {
    const searchKey = barcodeSearchOn ? 'numericSkuBarcode' : 'searchText';
    query += `&${searchKey}=${searchText}`;
  }
  if (selectedBranches && selectedBranches.length > 0) {
    query += `&branchId=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (selectedVoucherTypes) {
    query += `&statementType=${selectedVoucherTypes}`;
  }

  if (selectedParties && selectedParties.id) {
    query += `&partyId=${selectedParties.id}`;
  }

  if (excludeZeroBalanceItemGroup) {
    query += `&includingZeroClosingBalanceItemGroup=${excludeZeroBalanceItemGroup}`;
  }

  query = setIncludeTypeQuery(includeType, query);

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }
  dispatch({ type: REPORTS_DATA_REQUESTED });
  api
    .fetchInventoryGroup(id, itemGroupId, page, query)
    .then(response => {
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: SHOW_INVENTORY_GROUP_VIEW,
        payload: {
          response: response,
          groupId: itemGroupId
        }
      });
    })
    .catch(err => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const getInventoryGroupViewExport = (page = 1, itemGroupId, vendorId) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id },
    reports: {
      filters: {
        selectedParties,
        selectedBranches,
        selectedVoucherTypes,
        startDate,
        endDate,
        includeType,
        numericSkuBarcode,
        [INVENTORY_ITEM_NAME_SEARCH_KEY]: searchText,
        includeItemLabel,
        excludeZeroBalanceItemGroup,
        selectedSalesPerson
      }
    }
  } = getState();

  let query = `startDate=${getStartTimeOfDay(startDate)}&endDate=${getEndTimeOfDay(endDate)}`;
  if (searchText) {
    query += `&searchText=${searchText}`;
  }
  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (selectedVoucherTypes) {
    query += `&statementType=${selectedVoucherTypes}`;
  }

  if (selectedParties && selectedParties.id) {
    query += `&partyId=${selectedParties.id}`;
  }

  query = setIncludeTypeQuery(includeType, query);

  if (numericSkuBarcode && numericSkuBarcode !== '') {
    query += `&numericSkuBarcode=${numericSkuBarcode}`;
  }

  if (includeItemLabel) {
    query += `&includeItemLabel=${includeItemLabel}`;
  }

  if (excludeZeroBalanceItemGroup) {
    query += `&includingZeroClosingBalanceItemGroup=${excludeZeroBalanceItemGroup}`;
  }

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  if (vendorId) {
    query += `&vendorId=${vendorId}`;
  }

  api
    .fetchInventoryGroupExport(id, itemGroupId, page, query)
    .then(response => {
      // dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: PROGRESS_TRACKER,
        payload: {
          percentage: response.currentStatusInPercentage,
          isCompleted: response.isCompleted
        }
      });
      dispatch(trackExportStatus(response.id));
    })
    .catch(err => {
      // dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const handleItemStatement = (pageNo = 1, itemId) => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    // table: { sorting: sort },
    reports: {
      filters: {
        selectedParties,
        selectedBranches,
        selectedVoucherTypes,
        startDate,
        endDate,
        includeType,
        selectedSalesPerson
      }
    }
  } = getState();
  let query = `startDate=${getStartTimeOfDay(startDate)}&endDate=${getEndTimeOfDay(endDate)}`;

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&branchId=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (selectedVoucherTypes) {
    query += `&statementType=${selectedVoucherTypes}`;
  }

  if (selectedParties && selectedParties.id) {
    query += `&partyId=${selectedParties.id}`;
  }

  query = setIncludeTypeQuery(includeType, query);

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  api
    .fetchItemStatement(id, itemId, pageNo, query)
    .then(response => {
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: 'FETCH_ITEM_STATEMENT_VIEW',
        payload: {
          response: response
        }
      });
    })
    .catch(err => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const handleFetchInventoryMonthly = (page, itemId) => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    // table: { sorting: sort },
    reports: {
      filters: {
        selectedParties,
        selectedBranches,
        selectedVoucherTypes,
        startDate,
        endDate,
        includeType,
        selectedSalesPerson
      }
    }
  } = getState();
  let query = `startDate=${getStartTimeOfDay(startDate)}&endDate=${getEndTimeOfDay(endDate)}`;

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&branchId=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (selectedVoucherTypes) {
    query += `&statementType=${selectedVoucherTypes}`;
  }

  if (selectedParties && selectedParties.id) {
    query += `&partyId=${selectedParties.id}`;
  }

  query = setIncludeTypeQuery(includeType, query);

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  api
    .fetchInventoryMonthly(id, itemId, query)
    .then(response => {
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: FETCH_INVENTORY_MONTH_VIEW,
        payload: {
          response: response
        }
      });
    })
    .catch(err => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

//this actions get fired form here to render details view
// with the obj of  which details view has been ckicked
export const showDetailView = (key, obj) => dispatch => {
  dispatch({
    type: SHOW_DETAIL_VIEW
  });
  key && obj && dispatch(setActiveAccountGroup(key, obj));
};

export const setActiveAccountGroup = (key, obj) => ({
  type: SET_ROUTES_DETAILS,
  routes: {
    [key]: obj
  }
});

export const showLedgerView = () => {
  return {
    type: SHOW_LEDGER_VIEW
  };
};

export const setRoutingStatus = (isAccount, groupName) => {
  return {
    type: SET_ROUTING_STATUS,
    status: {
      isAccount: isAccount,
      groupName: groupName
    }
  };
};

export const resetToReportModeHome = () => dispatch => {
  dispatch(resetReportFilters());
  dispatch({ type: RESET_TO_REPORT_MODE_HOME });
};

export const resetToGroupViewHome = () => (dispatch, getState) => {
  const {
    reports: {
      headerProperties: { reportMode }
    }
  } = getState();
  if (reportMode === TAXES) {
    //For taxes need to fetch the data again
    dispatch(showAccountGroupViewAction(TAXES));
  }
  dispatch({
    type: RESET_GROUP_DETAILS
  });
};

export const showPeriodDialogAction = () => {
  return {
    type: SHOW_PERIOD_DIALOG
  };
};

export const expandAccount = (index, key) => {
  return {
    type: EXPAND_ACCOUNT,
    details: {
      index: index,
      key: key
    }
  };
};
export const expandAllAccount = data => {
  return {
    type: EXPAND_ALL_ACCOUNT,
    details: data
  };
};

export const changeDateRange = dateRange => (dispatch, getState) => {
  dispatch({
    type: CHANGE_DATE_RANGE,
    range: dateRange
  });
  let {
    reports: {
      headerProperties: {
        path,
        reportMode,
        showAccountStatementView,
        showYearlyView,
        takeGroupDetails,
        showInventoryGroupView
      }
    }
  } = getState();
  if (reportMode === TRIAL_BALANCE) {
    dispatch(fetchTrailBalance());
    dispatch(getLedgerWiseReport());
  } else if (reportMode === PROFIT_AND_LOSS) {
    dispatch(fetchProfitAndLoss());
  } else if (reportMode === BALANCE_SHEET) {
    dispatch(fetchBalanceSheet());
  } else if (reportMode === DAY_BOOK) {
    dispatch(showDayViewAction());
  } else if (reportMode === SUNDRY_DEBTORS) {
    dispatch(showAccountGroupViewAction(reportMode));
  } else if (reportMode === SUNDRY_CREDITORS) {
    dispatch(showAccountGroupViewAction(reportMode));
  } else if (reportMode === TAXES) {
    dispatch(showAccountGroupViewAction(reportMode));
  } else if (reportMode === EXPENSES) {
    dispatch(showAccountGroupViewAction(reportMode));
  } else if (reportMode === DIRECT_EXPENSES) {
    dispatch(showAccountGroupViewAction(reportMode));
  } else if (reportMode === INVENTORY) {
    if (showInventoryGroupView) {
      dispatch(showInventoryGroupViewAction());
    } else {
      dispatch(showInventoryAction());
    }
  }

  if (showAccountStatementView) {
    dispatch(showAccountStatementViewAction());
  } else if (showYearlyView) {
    dispatch(showYearlyViewAction());
  } else if (takeGroupDetails && path && reportMode !== INVENTORY) {
    dispatch(getAccountByPath());
  }
};
// function profitAndLossApiFn() {

// }
export const fetchProfitAndLoss = () => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: { startDate, endDate, includeType, selectedBranches, selectedVoucherTypes }
    }
  } = getState();

  let query = getDateQuery(startDate, endDate);

  query = setIncludeTypeQuery(includeType, query);
  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }
  if (selectedVoucherTypes) {
    query += `&statementType=${selectedVoucherTypes}`;
  }
  api
    .getProfitAndLoss(id, query)
    .then(response => {
      // adding open status key in profit and loss for expand and detail view feature.
      _.forEach(response, item => {
        if (Array.isArray(item)) {
          item.forEach(element => {
            element.open = false;
          });
        }
      });
      // sorting for direct expense
      // In this opening stock account is on first position.
      let sortedDirectExpense = [],
        sortedDirectRevenues = [],
        closingStockItem = {};
      response.directExpenses.forEach(item => {
        item.accountGroup === 'Opening Stock'
          ? sortedDirectExpense.unshift(item)
          : item.accountGroup === 'Closing Stock'
          ? (closingStockItem = item)
          : sortedDirectExpense.push(item);
      });
      Object.keys(closingStockItem).length > 1 && sortedDirectExpense.push(closingStockItem);
      closingStockItem = {};

      response.directRevenues.forEach(item => {
        item.accountGroup === 'Opening Stock'
          ? sortedDirectRevenues.unshift(item)
          : item.accountGroup === 'Closing Stock'
          ? (closingStockItem = item)
          : sortedDirectRevenues.push(item);
      });
      if (Object.keys(closingStockItem).length > 1) {
        sortedDirectRevenues.push(closingStockItem);
      }

      const sortedResult = {
        ...response,
        directExpenses: sortedDirectExpense,
        directRevenues: sortedDirectRevenues
      };
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({ type: SET_PROFIT_LOSS, response: sortedResult });
    })
    .catch(err => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};
export const getProfitAndLoss = () => dispatch => {
  dispatch({ type: SET_REPORT_MODE, reportMode: PROFIT_AND_LOSS });
  dispatch(fetchProfitAndLoss());
};

export const setReportMode = mode => () => {
  return {
    type: SET_REPORT_MODE,
    reportMode: mode
  };
};

export const fetchBalanceSheet = () => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: { startDate, endDate, includeType, selectedBranches, selectedVoucherTypes }
    }
  } = getState();

  let query = getDateQuery(startDate, endDate);

  query = setIncludeTypeQuery(includeType, query);
  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (selectedVoucherTypes) {
    query += `&statementType=${selectedVoucherTypes}`;
  }
  api
    .getBalanceSheet(id, query)
    .then(response => {
      _.forEach(response, item => {
        if (Array.isArray(item)) {
          item.forEach(element => {
            if (element.accountGroup === PROFIT_AND_LOSS_ACC) {
              element.open = true;
            } else {
              element.open = false;
            }
          });
        }
        // return item;
      });
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({ type: SET_BALANCE_SHEET, response: response });
    })
    .catch(err => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const getBalanceSheet = () => dispatch => {
  dispatch({ type: SET_REPORT_MODE, reportMode: BALANCE_SHEET });
  dispatch(fetchBalanceSheet());
};

// export const fetchDayBook = (voucherType, account) => (dispatch, getState) => {
//   dispatch({ type: REPORTS_DATA_REQUESTED });
//   const {
//     currentCompany: { id },
//     reports: { dateRange, dayBookVoucherType, dayBookAccountType }
//   } = getState();
//   const tempVoucherType = voucherType ? voucherType : dayBookVoucherType;
//   const tempAccount = account ? account : dayBookAccountType;
//   api
//     .fetchDayBook(id, dateRange.startDate, dateRange.endDate, tempVoucherType, tempAccount)
//     .then(response => {
//       dispatch({ type: REPORTS_DATA_SUCCESS });
//       dispatch({ type: SET_DAY_BOOK_LIST, response: response });
//     })
//     .catch(err => {
//       dispatch({ type: REPORTS_DATA_FAILED });
//       alert(err);
//     });
// };

export const setReportVoucherType = type => dispatch => {
  dispatch({ type: SET_REPORT_VOUCHER_TYPE, data: type });
};

// export const setDayBookAccountType = account => dispatch => {
//   dispatch({ type: SET_DAY_BOOK_ACCOUNT_TYPE, data: account });
//   dispatch(showDayViewAction());
// };

export const printLedgerWiseReport = payload => (dispatch, getState) => {
  const {
    currentCompany: { id },
    reports: {
      filters: { startDate, endDate }
    }
  } = getState();
  const query = `startDate=${getFormattedDateIso(startDate)}&endDate=${getFormattedDateIso(
    endDate
  )}`;
  return api
    .printLedgerWiseReport(id, payload, query)
    .then(res => {
      window.open(res.response, '_blank').focus();
      return res;
    })
    .catch(err => {
      // dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const toggleReportFilters = flag => dispatch => {
  dispatch({ type: TOGGLE_REPORT_FILTERS, flag });
};

export const resetReportFilters = () => dispatch => {
  dispatch({ type: RESET_REPORT_FILTERS });
};

export const setReportPartyFilter = selectedParties => dispatch => {
  dispatch({ type: SET_REPORT_PARTY_FILTER, selectedParties });
};

export const setReportSalesPersonFilter = selectedSalesPerson => dispatch => {
  dispatch({ type: SET_REPORT_SALES_PERSON_FILTER, selectedSalesPerson });
};

export const setReportPartyTagsFilter = selectedPartiesTags => dispatch => {
  dispatch({ type: SET_REPORT_TAGS_FILTER, selectedPartiesTags });
};

export const setReportItemGroupsFilter = selectedItemGroups => dispatch => {
  dispatch({ type: SET_REPORT_ITEM_GROUP_FILTER, selectedItemGroups });
};

export const setReportItemTagsFilter = selectedItemTags => dispatch => {
  dispatch({ type: SET_REPORT_ITEM_TAGS_FILTER, selectedItemTags });
};

export const setReportBranchFilter = selectedBranches => dispatch => {
  dispatch({ type: SET_REPORT_BRANCH_FILTER, selectedBranches });
};

export const setReportCityFilter = selectedCities => dispatch => {
  dispatch({ type: SET_REPORT_CITY_FILTER, selectedCities });
};

export const setReportSearchText = (searchText, key = REPORT_SEARCH_KEY) => dispatch => {
  dispatch({ type: SET_REPORT_SEARCH_TEXT, searchText, key });
};

export const toggleReportBarcodeSearch = (flag, key = REPORT_BARCODE_TOGGLE_KEY) => dispatch => {
  dispatch({ type: TOGGLE_REPORT_BARCODE_SEARCH, flag, key });
};

export const toggleReportPartyCode = (flag, key = INCLUDE_PARTY_CODE) => dispatch => {
  dispatch({ type: TOGGLE_REPORT_PARTY_CODE, flag, key });
};

export const toggleExportReportButtons = (flag, key) => dispatch => {
  dispatch({ type: TOGGLE_REPORT_EXPORT_BUTTONS, flag, key });
};

export const setReportPartiesStatesFilter = selectedParties => dispatch => {
  dispatch({ type: RESET_REPORT_CITY_FILTER });
  dispatch({ type: SET_REPORT_PARTIES_STATES_FILTER, selectedParties });
};

export const setReportVoucherTypeFilter = selectedVoucherTypes => dispatch => {
  dispatch({ type: SET_REPORT_VOUCHER_TYPE_FILTER, selectedVoucherTypes });
};

export const setReportDateFilter = (key, selectedDate) => dispatch => {
  dispatch({ type: SET_REPORT_DATE_FILTER, selectedDate, key });
};

export const setReportIntervalFilter = (selectedInterval, key = 'selectedInterval') => dispatch => {
  dispatch({ type: SET_REPORT_INTERVAL_FILTER, selectedInterval, key });
};

export const setReportDateFilterRange = (startDate, endDate) => dispatch => {
  dispatch({ type: SET_REPORT_DATE_FILTER_RANGE, startDate, endDate });
};

export const setReportDueDateFilterRange = (startDate, endDate) => dispatch => {
  dispatch({ type: SET_REPORT_DUE_DATE_FILTER_RANGE, startDate, endDate });
};

// export const setReportIncludeTypeFilter = (key, flag) => (dispatch, getState) => {
//   dispatch({ type: SET_REPORT_INCLUDE_TYPE_FILTER, key, flag });
// };
export const setReportIncludeTypeFilter = (key, flag) => dispatch => {
  dispatch({ type: SET_REPORT_INCLUDE_TYPE_FILTER, key, flag });
};

export const setNumericBarcodeFilter = payload => dispatch => {
  dispatch({ type: SET_NUMERIC_BARCODE_FILTER, payload });
};

export const setReportIncludePartyAccountTypes = includePartyAccountTypes => dispatch => {
  dispatch({ type: SET_REPORT_INCLUDE_SUNDRY_CRDR_FILTER, includePartyAccountTypes });
};

export const getStateReport = () => dispatch => {
  dispatch({ type: SET_REPORT_MODE, reportMode: STATE_AND_CITY });
  dispatch(fetchStateReport());
};

export const fetchStateReport = () => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: { startDate, endDate, selectedBranches }
    }
  } = getState();
  let query = getDateQuery(startDate, endDate);
  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }
  api
    .getStateReport(id, query)
    .then(response => {
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({ type: FETCH_STATE_REPORT_SUCCESS, payload: response });
    })
    .catch(error => {
      dispatch({ type: REPORTS_DATA_FAILED });
      dispatch({ type: FETCH_STATE_REPORT_FAIL, payload: error });
      serverError(error);
    });
};

export const getCityReport = page => dispatch => {
  dispatch({ type: SET_REPORT_MODE, reportMode: CITIES });
  dispatch(fetchCitiesReport(CITIES, page));
};

export const fetchCitiesReport = (groupType, page = 1) => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: { startDate, endDate, selectedPartiesStates, selectedCities, selectedBranches }
    }
  } = getState();
  let query = getDateQuery(startDate, endDate);
  if (selectedPartiesStates && selectedPartiesStates.length > 0) {
    query += `&state=${getValuesByKey(selectedPartiesStates).toString()}`;
  }
  if (selectedCities && selectedCities.length > 0) {
    query += `&city=${getValuesByKey(selectedCities).toString()}`;
  }
  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }
  api
    .getCityWiseReport(id, page, query)
    .then(response => {
      // const { data, ...rest } = response;
      // const payload = { ...rest, ...data };
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({ type: FETCH_CITY_REPORT_SUCCESS, payload: response });
    })
    .catch(error => {
      dispatch({ type: REPORTS_DATA_FAILED });
      dispatch({ type: FETCH_CITY_REPORT_FAIL, payload: error });
      serverError(error);
    });
};
export function setIncludeTypeQuery(includeType, query) {
  if (includeType.estimation || includeType.challan) {
    query += `&include=${includeType.estimation ? 'estimation' : ''},${
      includeType.challan ? 'challan' : ''
    }`;
  }
  return query;
}

function getDateQuery(startDate, endDate, statementType) {
  let query = `startTime=${getStartTimeOfDay(startDate)}&endTime=${getEndTimeOfDay(endDate)}`;

  if (statementType) {
    query += `&statementType=${statementType}`;
  }

  return query;
}

function getDueDateQuery(startDate, endDate) {
  let query = `dueStartDate=${getStartTimeOfDay(startDate)}&dueEndDate=${getEndTimeOfDay(endDate)}`;
  return query;
}

export const getGstr1Base = () => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    // table: { sorting: sort },
    reports: {
      filters: {
        // selectedParties,
        selectedBranches,
        // selectedVoucherTypes,
        startDate,
        endDate,
        includeType
      }
    }
  } = getState();
  let query = getDateQuery(startDate, endDate);

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }

  // if (selectedVoucherTypes) {
  //   query += `&statementType=${selectedVoucherTypes}`;
  // }

  // if (selectedParties && selectedParties.id) {
  //   query += `&partyId=${selectedParties.id}`;
  // }

  if (includeType) {
    query = setIncludeTypeQuery(includeType, query);
  }
  api
    .fetchGstr1Base(id, query)
    .then(response => {
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: SHOW_GSTR1_VIEW,
        response
      });
    })
    .catch(err => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const getGstr1Group = (page = 1, reportId) => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: { startDate, endDate, includeType, selectedBranches, selectedVoucherType }
    }
  } = getState();
  let query = getDateQuery(startDate, endDate);

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (includeType) {
    query = setIncludeTypeQuery(includeType, query);
  }
  // }

  query += `&type=${selectedVoucherType}`;
  api
    .fetchGstr1Group(id, reportId, page, query)
    .then(response => {
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: SHOW_GSTR1_GROUP_VIEW,
        response
      });
    })
    .catch(err => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const exportGstr1Group = (page = 1, reportId) => (dispatch, getState) => {
  const {
    currentCompany: { id },
    reports: {
      filters: { startDate, endDate, includeType, selectedBranches, selectedVoucherType }
    }
  } = getState();
  let query = getDateQuery(startDate, endDate);

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (includeType) {
    query = setIncludeTypeQuery(includeType, query);
  }

  query += `&type=${selectedVoucherType}`;
  api
    .exportGstr1Group(id, reportId, page, query)
    .then(response => {
      dispatch({
        type: PROGRESS_TRACKER,
        payload: {
          percentage: response.currentStatusInPercentage,
          isCompleted: response.isCompleted
        }
      });
      dispatch(trackExportStatus(response.id));
    })
    .catch(err => {
      serverError(err);
    });
};

export const getGstr1Statement = (page = 1, subTypeId, reportId) => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: { startDate, endDate, includeType, selectedBranches }
    }
  } = getState();
  let query = getDateQuery(startDate, endDate);

  if (includeType) {
    query = setIncludeTypeQuery(includeType, query);
  }
  // }
  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }
  api
    .fetchGstr1Statement(id, subTypeId, reportId, page, query)
    .then(response => {
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({
        type: SHOW_GSTR1_STATEMENT_VIEW,
        response
      });
    })
    .catch(err => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const exportSalesRegister = (startDate, endDate) => (dispatch, getState) => {
  dispatch({ type: EXPORT_REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id }
  } = getState();

  let query = `startDate=${getStartTimeOfDay(startDate)}&endDate=${getEndTimeOfDay(endDate)}`;

  api
    .fetchSalesRegisterExport(id, query)
    .then(response => {
      dispatch({ type: EXPORT_REPORTS_DATA_SUCCESS });
      if (response.id) {
        dispatch({
          type: PROGRESS_TRACKER,
          payload: {
            percentage: response.currentStatusInPercentage,
            isCompleted: response.isCompleted
          }
        });
        dispatch(toggleModalView(EXPORT_REPORT_MODAL, false));
        dispatch(trackExportStatus(response.id));
      } else {
        dispatch(openSnackbar(response, ERROR));
      }
    })
    .catch(err => {
      dispatch({ type: EXPORT_REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const exportSalesRegisterVIaItemUnitAndGroup = (startDate, endDate) => (
  dispatch,
  getState
) => {
  dispatch({ type: EXPORT_REPORTS_DATA_REQUESTED });

  const {
    currentCompany: { id }
  } = getState();

  let query = `startDate=${getStartTimeOfDay(startDate)}&endDate=${getEndTimeOfDay(endDate)}`;

  api
    .fetchSalesRegisterVIaItemUnitAndGroupExport(id, query)
    .then(response => {
      dispatch({ type: EXPORT_REPORTS_DATA_SUCCESS });
      if (response.id) {
        dispatch({
          type: PROGRESS_TRACKER,
          payload: {
            percentage: response.currentStatusInPercentage,
            isCompleted: response.isCompleted
          }
        });
        dispatch(toggleModalView(EXPORT_REPORT_MODAL, false));
        dispatch(trackExportStatus(response.id));
      } else {
        dispatch(openSnackbar(response, ERROR));
      }
    })
    .catch(err => {
      dispatch({ type: EXPORT_REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const exportPurchaseRegisterVIaItemUnitAndGroup = (startDate, endDate) => (
  dispatch,
  getState
) => {
  dispatch({ type: EXPORT_REPORTS_DATA_REQUESTED });

  const {
    currentCompany: { id }
  } = getState();

  let query = `startDate=${getStartTimeOfDay(startDate)}&endDate=${getEndTimeOfDay(endDate)}`;

  api
    .fetchPurchaseRegisterVIaItemUnitAndGroupExport(id, query)
    .then(response => {
      dispatch({ type: EXPORT_REPORTS_DATA_SUCCESS });
      if (response.id) {
        dispatch({
          type: PROGRESS_TRACKER,
          payload: {
            percentage: response.currentStatusInPercentage,
            isCompleted: response.isCompleted
          }
        });
        dispatch(toggleModalView(EXPORT_REPORT_MODAL, false));
        dispatch(trackExportStatus(response.id));
      } else {
        dispatch(openSnackbar(response, ERROR));
      }
    })
    .catch(err => {
      dispatch({ type: EXPORT_REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const exportPurchaseRegister = (startDate, endDate) => (dispatch, getState) => {
  dispatch({ type: EXPORT_REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id }
  } = getState();

  let query = `startDate=${getStartTimeOfDay(startDate)}&endDate=${getEndTimeOfDay(endDate)}`;

  api
    .fetchPurchaseRegisterExport(id, query)
    .then(response => {
      dispatch({ type: EXPORT_REPORTS_DATA_SUCCESS });
      if (response.id) {
        dispatch({
          type: PROGRESS_TRACKER,
          payload: {
            percentage: response.currentStatusInPercentage,
            isCompleted: response.isCompleted
          }
        });
        dispatch(toggleModalView(EXPORT_REPORT_MODAL, false));
        dispatch(trackExportStatus(response.id));
      } else {
        dispatch(openSnackbar(response, ERROR));
      }
    })
    .catch(err => {
      dispatch({ type: EXPORT_REPORTS_DATA_FAILED });
      serverError(err);
    });
};

export const fetchVouchersByNumber = input => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  fetchVouchersSuggestionByNo(id, input)
    .then(response => {
      dispatch({
        type: FETCH_REPORT_VOUCHERS_LIST,
        response
      });
    })
    .catch(error => {
      dispatch(openSnackbar(error, ERROR));
    });
};
// DAYBOOK
export const setVoucherFilter = id => dispatch => {
  dispatch({
    type: SET_REPORT_VOUCHER_FILTER,
    id
  });
};

export const fetchSalesPersonReport = () => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: { selectedBranches, startDate, endDate }
    }
  } = getState();
  let query = `startDate=${getStartTimeOfDay(startDate)}&endDate=${getEndTimeOfDay(endDate)}`;

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }

  api
    .fetchSalesPersonReport(id, query)
    .then(response => {
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({ type: SET_SALES_PERSON_DETAILS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(error);
    });
};

export const fetchVendorWiseReport = (pageNo = 1) => (dispatch, getState) => {
  dispatch({ type: REPORTS_DATA_REQUESTED });
  const {
    currentCompany: { id },
    reports: {
      filters: {
        startDate,
        endDate,
        selectedBranches,
        includeType,
        selectedVoucherTypes,
        selectedParties,
        selectedSalesPerson,
        selectedItemGroups,
        searchText
      }
    }
  } = getState();

  let query = `startDate=${getStartTimeOfDay(startDate)}&endDate=${getEndTimeOfDay(endDate)}`;

  if (selectedVoucherTypes) {
    query += `&statementType=${selectedVoucherTypes}`;
  }

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (includeType) {
    query = `&${setIncludeTypeQuery(includeType, query)}`;
  }

  if (selectedParties && selectedParties.id) {
    query += `&partyId=${selectedParties.id}`;
  }

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  if (selectedItemGroups && selectedItemGroups.length > 0) {
    query += `&itemGroupIds=${getValuesByKey(selectedItemGroups).toString()}`;
  }

  if (searchText) {
    query += `&searchText=${searchText}`;
  }

  api
    .fetchVendorWiseReport(id, query, pageNo)
    .then(response => {
      dispatch({ type: REPORTS_DATA_SUCCESS });
      dispatch({ type: SET_VENDOR_WISE_DETAILS, payload: response });
    })
    .catch(error => {
      dispatch({ type: REPORTS_DATA_FAILED });
      serverError(error);
    });
};

export const exportVendorWiseReport = (pageNo = 1) => (dispatch, getState) => {
  const {
    currentCompany: { id },
    reports: {
      filters: {
        startDate,
        endDate,
        selectedBranches,
        includeType,
        selectedVoucherTypes,
        selectedParties,
        selectedSalesPerson,
        selectedItemGroups,
        searchText
      }
    }
  } = getState();

  let query = `startDate=${getStartTimeOfDay(startDate)}&endDate=${getEndTimeOfDay(endDate)}`;

  if (selectedVoucherTypes) {
    query += `&statementType=${selectedVoucherTypes}`;
  }

  if (selectedBranches && selectedBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }

  if (includeType) {
    query = `&${setIncludeTypeQuery(includeType, query)}`;
  }

  if (selectedParties && selectedParties.id) {
    query += `&partyId=${selectedParties.id}`;
  }

  if (selectedSalesPerson && selectedSalesPerson.id) {
    query += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  if (selectedItemGroups && selectedItemGroups.length > 0) {
    query += `&itemGroupIds=${getValuesByKey(selectedItemGroups).toString()}`;
  }

  if (searchText) {
    query += `&searchText=${searchText}`;
  }

  api
    .exportVendorWiseReport(id, query, pageNo)
    .then(response => {
      dispatch({
        type: PROGRESS_TRACKER,
        payload: {
          percentage: response.currentStatusInPercentage,
          isCompleted: response.isCompleted
        }
      });
      dispatch(trackExportStatus(response.id));
    })
    .catch(error => {
      serverError(error);
    });
};
